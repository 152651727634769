import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { useRecoilState } from 'recoil';

import Layout from 'layout';
// Private Route Component
import PrivateRoute from 'components/PrivateRoute';
// UI
import LoadingPage from 'ui/LoadingPage';
// Import from State
import { profileState, isLoggedIn as loginState, configurationState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';

// Lazy - Booking Routes
const ListWalletCards = React.lazy(() => import('components/Payments/Wallet/List'));
const ViewWalletCards = React.lazy(() => import('components/Payments/Wallet/View/ViewParent'));
const SearchTransactions = React.lazy(() => import('components/Payments/Transactions/List'));
const ViewTransaction = React.lazy(() => import('components/Payments/Transactions/View/ViewParent'));

function PaymentsPage() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }
    if (isLoggedIn) return;
    initialize();
  }, []);

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout showLogoutButton>
          <div className="w-full">
            <div className="pl-4 pr-4 pt-6 md:pl-8 md:pr-8 xl:pr-16 mb-20">
              <React.Suspense fallback="">
                <Router>
                  <PrivateRoute
                    path="/payment/card/list"
                    component={ListWalletCards}
                    isAllowed={profile.options.booking.payment}
                    navUrl="/404"
                  />
                  <PrivateRoute
                    path="/payment/card/view/:id"
                    component={ViewWalletCards}
                    isAllowed={profile.options.booking.payment}
                    navUrl="/404"
                  />
                  <PrivateRoute
                    path="/payment/transactions"
                    component={SearchTransactions}
                    isAllowed={profile.options.booking.payment}
                    navUrl="/404"
                  />
                  <PrivateRoute
                    path="/payment/transaction/:id"
                    component={ViewTransaction}
                    isAllowed={profile.options.booking.payment}
                    navUrl="/404"
                  />
                </Router>
              </React.Suspense>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default PaymentsPage;
